<template>
	<errorContainer :error="error" :warning="warning">
		<transition name="slide">
			<div class="container-mobile" v-if="document">
				<div class="my-expense-mobile">
					<div class="title">
						<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
							{{ FormMSG(1, 'Document details') }}
						</h1>
					</div>
				</div>
				<div class="back-with-title cursor-pointer" :class="{ 'mt-16': $isPwa() }" @click="goBack()">
					<div class="icon">
						<component :is="getLucideIcon('ArrowLeft')" color="#667987" :size="22" />
					</div>
					<h2>{{ document.name }}</h2>
				</div>
				<div class="title-ts-detail text-center py-2" v-if="document.type === 11 || document.type === 9">
					<div class="date-title pr-3">
						{{ dateToString(document.deliveryContractStrDate) }} -
						{{ dateToString(document.deliveryContractEndDate) }}
					</div>
				</div>
				<div class="form mt-16">
					<div class="content">
						<b-row>
							<b-col cols="12">
								<label class="fz-14px">{{ FormMSG(2, 'Type') }}</label>
								<div class="mt-minus-4px">{{ contractTranslation(document.type) }}</div>
							</b-col>
						</b-row>
						<b-row class="pt-3" v-if="document.publisher">
							<b-col cols="12">
								<label class="fz-14px">{{ FormMSG(3, 'Shared by') }}</label>
								<div class="mt-minus-4px">{{ `${document.publisher.name} ${document.publisher.firstName}` }}</div>
							</b-col>
						</b-row>
						<b-row class="pt-3">
							<b-col cols="12">
								<label class="fz-14px">{{ FormMSG(4, 'Received') }}</label>
								<div class="mt-minus-4px">{{ getLiteralDate(document.publishedOn) }}</div>
							</b-col>
						</b-row>
						<b-row class="pt-3" v-if="document.body && document.body.length > 0">
							<b-col cols="12">
								<label class="fz-14px">{{ FormMSG(5, 'Message') }}</label>
								<div class="mt-minus-4px">
									{{ document.body }}
								</div>
							</b-col>
						</b-row>
						<div class="divider-entire mt-16" />
						<div class="sub-title pt-2">
							{{ FormMSG(7, 'Document list') }}
						</div>
						<b-row
							v-for="(file, index) in fileList"
							:key="file.id"
							:class="`${index % 2 === 0 ? '' : index === fileList.length - 1 ? 'pt-3' : 'py-3'}`"
						>
							<b-col cols="3">
								<div class="document-file-list">
									<img :src="`svgs/bootstrap/filetype-${getExtensionFile(file.fileName)}.svg`" />
									<div class="icon d-flex justify-content-center align-items-center">
										<button class="btn-transparent text-color-rhapsody-in-blue" @click="handlePreviewDocument(file)">
											<component :is="getLucideIcon('Eye')" color="#225CBD" :size="26" />
										</button>
									</div>
								</div>
							</b-col>
							<b-col cols="9" class="document-file-list-col-2">
								<b-row>
									{{ getFileName(file.fileName) }}
								</b-row>
								<b-row>
									<span class="wrap-status d-flex justify-content-start">
										<span :class="`status ${classDocumentStatus(file.status)}`">
											{{ validatedStatus(file.status) }}
										</span>
									</span>
								</b-row>
							</b-col>
						</b-row>
						<div v-if="document.type === 11">
							<div class="divider-entire mt-16" />
							<div class="sub-title pt-2">
								{{ FormMSG(8, 'My documents') }}
							</div>
							<b-row
								v-for="(file, index) in mineFiles"
								:key="file.id"
								:class="`${index % 2 === 0 ? '' : index === mineFiles.length - 1 ? 'pt-3' : 'py-3'}`"
							>
								<b-col cols="3">
									<div class="document-file-list">
										<img :src="`svgs/bootstrap/filetype-${getExtensionFile(file.fileName)}.svg`" />
										<div class="icon d-flex justify-content-center align-items-center">
											<button class="btn-transparent text-color-rhapsody-in-blue" @click="handlePreviewDocument(file)">
												<component :is="getLucideIcon('Eye')" color="#225CBD" :size="26" />
											</button>
										</div>
									</div>
								</b-col>
								<b-col cols="9" class="document-file-list-col-2">
									<div style="height: 50% !important">
										<b-row>
											{{ getFileName(file.fileName) }}
										</b-row>
										<b-row>
											<span class="wrap-status d-flex justify-content-start">
												<span :class="`status ${classDocumentStatus(file.status)}`">
													{{ validatedStatus(file.status) }}
												</span>
											</span>
										</b-row>
									</div>
									<div style="height: 50% !important" class="d-flex align-items-end">
										<b-row class="d-flex justify-content-start">
											<button class="btn-transparent pl-0" @click="handleRemoveDocument(file.id)">
												<component :is="getLucideIcon('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
											</button>
										</b-row>
									</div>
								</b-col>
							</b-row>
							<div class="add-doc-package mt-3">
								<div class="add-doc-package-action">
									<button class="btn-transparent text-color-white" @click="handleClickNewDocument">
										<component :is="getLucideIcon('PlusCircle')" :size="56" :stroke-width="0.5" />
									</button>
								</div>
							</div>
						</div>
						<div class="pt-3" />
					</div>
				</div>
				<add-document :open="showModalDocument" :parent-id="parent" @add-document-modal:hide="onCancelled" @add-document-modal:success="onSuccess" />
			</div>
		</transition>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import * as icons from 'lucide-vue';
import moment from 'moment';
import { classDocumentStatus } from '@/shared/global-status';
import { isNil } from '~utils';
import AddDocument from '@/views/documents/AddDocumentModal';
import globalMixin from '@/mixins/global.mixin';
import { deleteDocument } from '@/modules/document-package/cruds/document.crud';

export default {
	name: 'MyDocumentDetailsMobile',
	mixins: [languageMessages, globalMixin],
	props: {
		document: {
			type: Object,
			required: true,
			default: () => {}
		},
		fileList: {
			type: Array,
			required: true,
			default: () => []
		},
		mineFileList: {
			type: Array,
			required: true,
			default: () => []
		},
		parentId: {
			type: [Number, String],
			required: true,
			default: 0
		}
	},
	components: {
		AddDocument
	},
	data() {
		return {
			error: {},
			warning: '',
			mineFiles: [],
			showModalDocument: false,
			parent: 0
		};
	},
	methods: {
		getLucideIcon(name) {
			return icons[name];
		},
		goBack() {
			const path = '/mydocuments';
			this.$router.push({ path });
		},
		contractTranslation(value) {
			const contractTypes = this.FormMenu(1011);
			const contractType = contractTypes.filter((contractType) => contractType.value === value);

			return contractType.length > 0 ? contractType[0].text : '';
		},
		getLiteralDate(date) {
			const langCurrent = this.currentLang;
			const fullLiteralDate = moment(date)
				.locale(`${langCurrent}`)
				.format(`dddd, Do MMMM YYYY [${this.FormMSG(6, 'at')}] HH:mm`);

			return fullLiteralDate;
		},
		getExtensionFile(filename) {
			return filename.substring(filename.lastIndexOf('.') + 1);
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		validatedStatus(value) {
			const statuses = this.FormMenu(1012);
			const status = statuses.filter((status) => status.value === value);

			return status.length > 0 ? status[0].text : '';
		},
		classDocumentStatus(status) {
			return classDocumentStatus(status);
		},
		onCancelled() {
			this.showModalDocument = false;
		},
		onSuccess(payload) {
			if (payload) {
				this.createToastForMobile(this.FormMSG(9, 'Success'), this.FormMSG(10, 'Document added successfully'), '');
				this.mineFiles.push(payload);
				this.showModalDocument = false;
			}
		},
		handleClickNewDocument() {
			this.showModalDocument = true;
		},
		handlePreviewDocument(file) {
			const fileToPreview = {
				...file,
				status: this.manageStatusInAdvance(file.status)
			};
			this.$previewImages({
				name: 'mydocumentdetails-previewer-elem',
				images: [fileToPreview],
				focusIndex: 0,
				options: {
					presentationMode: 'doc',
					// showStatusFileTag: true,
					hideCommentButton: false,
					hideDisLikeButton: false,
					hideLikeDislike: false,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: true
				}
			});
		},
		handleRemoveDocument(id) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(11, 'Are you sure to remove this document ?'), {
					title: this.FormMSG(12, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(13, 'YES'),
					cancelTitle: this.FormMSG(14, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					await deleteDocument(id)
						.then((result) => {
							if (result) {
								this.mineFiles.splice(
									this.mineFiles.findIndex((file) => file.id === id),
									1
								);
							}
						})
						.catch((e) => console.log(e));
				})
				.catch((e) => console.log(e));
		},
		dateToString(date) {
			return new moment(date).utc().format('DD/MM/YYYY');
		}
	},
	watch: {
		mineFileList: {
			handler(value) {
				if (!isNil(value)) {
					this.mineFiles = value;
				}
			},
			deep: true,
			immediate: true
		},
		parentId: {
			handler(value) {
				if (!isNil(value)) {
					this.parent = value;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss" scoped>
.title-ts-detail {
	border-bottom: 0.005rem solid rgba(226, 230, 235, 0.85);
	margin-left: -12px;
	margin-right: -12px;
}
.date-title {
	font-weight: 500;
	font-size: 1.265rem;
	color: rgba(6, 38, 62, 0.74);
}
</style>
