var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.document
          ? _c(
              "div",
              { staticClass: "container-mobile" },
              [
                _c("div", { staticClass: "my-expense-mobile" }, [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "main-page-title",
                        class: { "is-pwa": _vm.$isPwa() },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(1, "Document details")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "back-with-title cursor-pointer",
                    class: { "mt-16": _vm.$isPwa() },
                    on: {
                      click: function ($event) {
                        return _vm.goBack()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c(_vm.getLucideIcon("ArrowLeft"), {
                          tag: "component",
                          attrs: { color: "#667987", size: 22 },
                        }),
                      ],
                      1
                    ),
                    _c("h2", [_vm._v(_vm._s(_vm.document.name))]),
                  ]
                ),
                _vm.document.type === 11 || _vm.document.type === 9
                  ? _c(
                      "div",
                      { staticClass: "title-ts-detail text-center py-2" },
                      [
                        _c("div", { staticClass: "date-title pr-3" }, [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.dateToString(
                                  _vm.document.deliveryContractStrDate
                                )
                              ) +
                              " -\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.dateToString(
                                  _vm.document.deliveryContractEndDate
                                )
                              ) +
                              "\n\t\t\t\t"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "form mt-16" }, [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c("label", { staticClass: "fz-14px" }, [
                              _vm._v(_vm._s(_vm.FormMSG(2, "Type"))),
                            ]),
                            _c("div", { staticClass: "mt-minus-4px" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.contractTranslation(_vm.document.type)
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm.document.publisher
                        ? _c(
                            "b-row",
                            { staticClass: "pt-3" },
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c("label", { staticClass: "fz-14px" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(3, "Shared by"))),
                                ]),
                                _c("div", { staticClass: "mt-minus-4px" }, [
                                  _vm._v(
                                    _vm._s(
                                      `${_vm.document.publisher.name} ${_vm.document.publisher.firstName}`
                                    )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        { staticClass: "pt-3" },
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c("label", { staticClass: "fz-14px" }, [
                              _vm._v(_vm._s(_vm.FormMSG(4, "Received"))),
                            ]),
                            _c("div", { staticClass: "mt-minus-4px" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getLiteralDate(_vm.document.publishedOn)
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm.document.body && _vm.document.body.length > 0
                        ? _c(
                            "b-row",
                            { staticClass: "pt-3" },
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c("label", { staticClass: "fz-14px" }, [
                                  _vm._v(_vm._s(_vm.FormMSG(5, "Message"))),
                                ]),
                                _c("div", { staticClass: "mt-minus-4px" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.document.body) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "divider-entire mt-16" }),
                      _c("div", { staticClass: "sub-title pt-2" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(7, "Document list")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._l(_vm.fileList, function (file, index) {
                        return _c(
                          "b-row",
                          {
                            key: file.id,
                            class: `${
                              index % 2 === 0
                                ? ""
                                : index === _vm.fileList.length - 1
                                ? "pt-3"
                                : "py-3"
                            }`,
                          },
                          [
                            _c("b-col", { attrs: { cols: "3" } }, [
                              _c("div", { staticClass: "document-file-list" }, [
                                _c("img", {
                                  attrs: {
                                    src: `svgs/bootstrap/filetype-${_vm.getExtensionFile(
                                      file.fileName
                                    )}.svg`,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "icon d-flex justify-content-center align-items-center",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-transparent text-color-rhapsody-in-blue",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePreviewDocument(
                                              file
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Eye"), {
                                          tag: "component",
                                          attrs: { color: "#225CBD", size: 26 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c(
                              "b-col",
                              {
                                staticClass: "document-file-list-col-2",
                                attrs: { cols: "9" },
                              },
                              [
                                _c("b-row", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.getFileName(file.fileName)) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                                _c("b-row", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "wrap-status d-flex justify-content-start",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: `status ${_vm.classDocumentStatus(
                                            file.status
                                          )}`,
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.validatedStatus(file.status)
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm.document.type === 11
                        ? _c(
                            "div",
                            [
                              _c("div", {
                                staticClass: "divider-entire mt-16",
                              }),
                              _c("div", { staticClass: "sub-title pt-2" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(8, "My documents")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]),
                              _vm._l(_vm.mineFiles, function (file, index) {
                                return _c(
                                  "b-row",
                                  {
                                    key: file.id,
                                    class: `${
                                      index % 2 === 0
                                        ? ""
                                        : index === _vm.mineFiles.length - 1
                                        ? "pt-3"
                                        : "py-3"
                                    }`,
                                  },
                                  [
                                    _c("b-col", { attrs: { cols: "3" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "document-file-list" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: `svgs/bootstrap/filetype-${_vm.getExtensionFile(
                                                file.fileName
                                              )}.svg`,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "icon d-flex justify-content-center align-items-center",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-transparent text-color-rhapsody-in-blue",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handlePreviewDocument(
                                                        file
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(_vm.getLucideIcon("Eye"), {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#225CBD",
                                                      size: 26,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "document-file-list-col-2",
                                        attrs: { cols: "9" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              height: "50% !important",
                                            },
                                          },
                                          [
                                            _c("b-row", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.getFileName(
                                                      file.fileName
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]),
                                            _c("b-row", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "wrap-status d-flex justify-content-start",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      class: `status ${_vm.classDocumentStatus(
                                                        file.status
                                                      )}`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.validatedStatus(
                                                              file.status
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-end",
                                            staticStyle: {
                                              height: "50% !important",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent pl-0",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleRemoveDocument(
                                                          file.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "Trash2"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          size: 24,
                                                          "stroke-width": 1.5,
                                                          color: "#ea4e2c",
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "div",
                                { staticClass: "add-doc-package mt-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "add-doc-package-action" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent text-color-white",
                                          on: {
                                            click: _vm.handleClickNewDocument,
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("PlusCircle"), {
                                            tag: "component",
                                            attrs: {
                                              size: 56,
                                              "stroke-width": 0.5,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "pt-3" }),
                    ],
                    2
                  ),
                ]),
                _c("add-document", {
                  attrs: {
                    open: _vm.showModalDocument,
                    "parent-id": _vm.parent,
                  },
                  on: {
                    "add-document-modal:hide": _vm.onCancelled,
                    "add-document-modal:success": _vm.onSuccess,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }