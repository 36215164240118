var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.error } }, [
    _vm.$screen.width <= 992
      ? _c(
          "div",
          [
            _c("my-document-details-mobile", {
              attrs: {
                document: _vm.document,
                "file-list": _vm.fileList,
                "mine-file-list": _vm.mineFileList,
                "parent-id": _vm.parentId,
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "animated fadeIn pb-2" },
          [
            _c("b-col", { attrs: { sm: "12", md: "12", lg: "12", xl: "12" } }, [
              _c(
                "h1",
                {
                  staticClass: "px-0",
                  class: [
                    `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                    { "is-pwa": _vm.$isPwa() },
                  ],
                },
                [_vm._v(_vm._s(_vm.FormMSG(1, "Document details")))]
              ),
            ]),
            _c(
              "div",
              { staticClass: "container-layout details-document-package" },
              [
                _vm.document && _vm.document.publisher
                  ? _c(
                      "b-card",
                      {
                        staticClass: "card-border-blue-light pb-4",
                        attrs: { "no-body": "" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "back-with-title cursor-pointer py-1",
                            class: { "mt-16": _vm.$isPwa() },
                            on: {
                              click: function ($event) {
                                return _vm.goBack()
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-9 d-flex justify-content-start align-items-center pl-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "icon" },
                                  [
                                    _c(_vm.getIconLucide("ArrowLeft"), {
                                      tag: "component",
                                      staticClass: "icon",
                                      attrs: {
                                        color: "rgba(6, 38, 62, 0.65)",
                                        size: 22,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("h2", [_vm._v(_vm._s(_vm.document.name))]),
                              ]
                            ),
                            _vm.document.type === 11 || _vm.document.type === 9
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-3 d-flex justify-content-end font-weight-bold contract-date",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.dateToString(
                                            _vm.document.deliveryContractStrDate
                                          )
                                        ) +
                                        " -\n\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.dateToString(
                                            _vm.document.deliveryContractEndDate
                                          )
                                        ) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "form pt-3 px-1" },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      sm: "12",
                                      md: "12",
                                      lg: "4",
                                      xl: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(2, "Type"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            value: `${_vm.contractTranslation(
                                              _vm.document.type
                                            )}`,
                                            type: "text",
                                            disabled: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      sm: "12",
                                      md: "12",
                                      lg: "4",
                                      xl: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(3, "Shared by"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            value: `${_vm.document.publisher.name} ${_vm.document.publisher.firstName}`,
                                            type: "text",
                                            disabled: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      sm: "12",
                                      md: "12",
                                      lg: "4",
                                      xl: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(7, "Received"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            disabled: "",
                                            type: "text",
                                            value: `${_vm.getLiteralDate(
                                              _vm.document.publishedOn
                                            )}`,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(4, "Message"),
                                        },
                                      },
                                      [
                                        _c("b-form-textarea", {
                                          attrs: {
                                            disabled: "",
                                            type: "textarea",
                                            value: _vm.document.body,
                                            rows: "3",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "divider" }),
                        _c(
                          "div",
                          {
                            staticClass: "sub-title",
                            staticStyle: { color: "rgba(6, 38, 62, 0.65)" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(5, "Document list")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "pt-3 px-3" },
                          [
                            _c(
                              "b-row",
                              _vm._l(_vm.fileList, function (file, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: index,
                                    staticClass:
                                      "d-flex flex-column justify-content-center align-items-center",
                                    attrs: {
                                      sm: "2",
                                      md: "2",
                                      lg: "2",
                                      xl: "2",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "doc-list-package size",
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.handleMouseEnter(file)
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.handleMouseLeave(file)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "doc-list-package-action",
                                            style: `height: ${
                                              file.isHovered ? "100%" : "0"
                                            }`,
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "container-doc-list-package-action",
                                                class: {
                                                  "d-block": file.isHovered,
                                                  "d-none": !file.isHovered,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn-transparent text-color-rhapsody-in-blue",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handlePreviewDocument(
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("eye", {
                                                          attrs: {
                                                            size: 40,
                                                            "stroke-width": 1.5,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("img", {
                                          staticClass: "file",
                                          attrs: {
                                            src: `svgs/bootstrap/filetype-${_vm.getExtensionFile(
                                              file
                                            )}.svg`,
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "file-name mt-3" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.getFileName(file.fileName)
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "wrap-status mt-1 pb-4" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: `status ${_vm.classDocumentStatus(
                                              file.status
                                            )}`,
                                            staticStyle: {
                                              "font-size": "0.7rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.validatedStatus(
                                                    file.status
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.document.type === 11
                          ? _c("div", [
                              _c("div", { staticClass: "divider mt-0" }),
                              _c(
                                "div",
                                {
                                  staticClass: "sub-title",
                                  staticStyle: {
                                    color: "rgba(6, 38, 62, 0.65)",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(6, "My documents")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "px-3" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _vm._l(
                                        _vm.mineFileList,
                                        function (file, index) {
                                          return _c(
                                            "b-col",
                                            {
                                              key: index,
                                              staticClass:
                                                "d-flex flex-column justify-content-center align-items-center pt-3",
                                              attrs: {
                                                md: "2",
                                                lg: "2",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "doc-list-package size",
                                                  on: {
                                                    mouseenter: function (
                                                      $event
                                                    ) {
                                                      return _vm.handleMouseEnter(
                                                        file
                                                      )
                                                    },
                                                    mouseleave: function (
                                                      $event
                                                    ) {
                                                      return _vm.handleMouseLeave(
                                                        file
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "doc-list-package-action",
                                                      style: `height: ${
                                                        file.isHovered
                                                          ? "100%"
                                                          : "0"
                                                      }`,
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "container-doc-list-package-action",
                                                          class: {
                                                            "d-block":
                                                              file.isHovered,
                                                            "d-none":
                                                              !file.isHovered,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-70",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn-transparent text-color-rhapsody-in-blue",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handlePreviewDocument(
                                                                          file
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("eye", {
                                                                    attrs: {
                                                                      size: 40,
                                                                      "stroke-width": 1.5,
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c("div", [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent text-color-burning-tomato",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleRemoveDocument(
                                                                        file.id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("trash-2", {
                                                                  attrs: {
                                                                    size: 40,
                                                                    "stroke-width": 1.5,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("img", {
                                                    staticClass: "file",
                                                    attrs: {
                                                      src: `svgs/bootstrap/filetype-${_vm.getExtensionFile(
                                                        file
                                                      )}.svg`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "file-name mt-3",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.getFileName(
                                                          file.fileName
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "wrap-status mt-1 pb-4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: `status ${_vm.classDocumentStatus(
                                                        file.status
                                                      )}`,
                                                      staticStyle: {
                                                        "font-size": "0.7rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.validatedStatus(
                                                              file.status
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "add-doc-package ml-3 mt-3",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "add-doc-package-action",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-transparent text-color-white",
                                                  on: {
                                                    click:
                                                      _vm.handleClickNewDocument,
                                                  },
                                                },
                                                [
                                                  _c("plus-circle", {
                                                    attrs: {
                                                      size: 75,
                                                      "stroke-width": 0.5,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("add-document", {
              attrs: { open: _vm.showModalDocument, "parent-id": _vm.parentId },
              on: {
                "add-document-modal:hide": _vm.onCancelled,
                "add-document-modal:success": _vm.onSuccess,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }