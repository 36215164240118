<style lang="scss" scoped>
.contract-date {
	font-size: 1.265rem;
	color: rgba(6, 38, 62, 0.65);
	margin-top: -4px;
}
</style>
<template>
	<errorContainer :error="error">
		<div v-if="$screen.width <= 992">
			<my-document-details-mobile :document="document" :file-list="fileList" :mine-file-list="mineFileList" :parent-id="parentId" />
		</div>
		<div class="animated fadeIn pb-2" v-else>
			<b-col sm="12" md="12" lg="12" xl="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]" class="px-0">{{ FormMSG(1, 'Document details') }}</h1>
			</b-col>
			<div class="container-layout details-document-package">
				<b-card no-body class="card-border-blue-light pb-4" v-if="document && document.publisher">
					<!-- <pre>{{ document }}</pre> -->
					<div class="back-with-title cursor-pointer py-1" :class="{ 'mt-16': $isPwa() }" @click="goBack()">
						<div class="col-9 d-flex justify-content-start align-items-center pl-0">
							<div class="icon">
								<component :is="getIconLucide('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
							</div>
							<h2>{{ document.name }}</h2>
						</div>
						<div class="col-3 d-flex justify-content-end font-weight-bold contract-date" v-if="document.type === 11 || document.type === 9">
							{{ dateToString(document.deliveryContractStrDate) }} -
							{{ dateToString(document.deliveryContractEndDate) }}
						</div>
					</div>
					<div class="form pt-3 px-1">
						<b-row>
							<b-col sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(2, 'Type')">
									<b-form-input :value="`${contractTranslation(document.type)}`" type="text" disabled />
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(3, 'Shared by')">
									<b-form-input :value="`${document.publisher.name} ${document.publisher.firstName}`" type="text" disabled />
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(7, 'Received')">
									<b-form-input disabled type="text" :value="`${getLiteralDate(document.publishedOn)}`" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<b-form-group :label="FormMSG(4, 'Message')">
									<b-form-textarea disabled type="textarea" :value="document.body" rows="3" />
								</b-form-group>
							</b-col>
						</b-row>
					</div>
					<div class="divider" />
					<div class="sub-title" style="color: rgba(6, 38, 62, 0.65)">
						{{ FormMSG(5, 'Document list') }}
					</div>
					<div class="pt-3 px-3">
						<b-row>
							<b-col
								v-for="(file, index) in fileList"
								:key="index"
								sm="2"
								md="2"
								lg="2"
								xl="2"
								class="d-flex flex-column justify-content-center align-items-center"
							>
								<div class="doc-list-package size" @mouseenter="handleMouseEnter(file)" @mouseleave="handleMouseLeave(file)">
									<div class="doc-list-package-action" :style="`height: ${file.isHovered ? '100%' : '0'}`">
										<div
											class="container-doc-list-package-action"
											:class="{
												'd-block': file.isHovered,
												'd-none': !file.isHovered
											}"
										>
											<div class="mb-0">
												<button class="btn-transparent text-color-rhapsody-in-blue" @click="handlePreviewDocument(file)">
													<eye :size="40" :stroke-width="1.5" />
												</button>
											</div>
										</div>
									</div>
									<img :src="`svgs/bootstrap/filetype-${getExtensionFile(file)}.svg`" class="file" />
								</div>
								<div class="file-name mt-3">
									{{ getFileName(file.fileName) }}
								</div>
								<div class="wrap-status mt-1 pb-4">
									<div :class="`status ${classDocumentStatus(file.status)}`" style="font-size: 0.7rem">
										{{ validatedStatus(file.status) }}
									</div>
								</div>
							</b-col>
						</b-row>
					</div>
					<div v-if="document.type === 11">
						<div class="divider mt-0" />
						<div class="sub-title" style="color: rgba(6, 38, 62, 0.65)">
							{{ FormMSG(6, 'My documents') }}
						</div>
						<div class="px-3">
							<b-row>
								<b-col
									v-for="(file, index) in mineFileList"
									:key="index"
									md="2"
									lg="2"
									xl="2"
									class="d-flex flex-column justify-content-center align-items-center pt-3"
								>
									<div class="doc-list-package size" @mouseenter="handleMouseEnter(file)" @mouseleave="handleMouseLeave(file)">
										<div class="doc-list-package-action" :style="`height: ${file.isHovered ? '100%' : '0'}`">
											<div
												class="container-doc-list-package-action"
												:class="{
													'd-block': file.isHovered,
													'd-none': !file.isHovered
												}"
											>
												<div class="mb-70">
													<button class="btn-transparent text-color-rhapsody-in-blue" @click="handlePreviewDocument(file)">
														<eye :size="40" :stroke-width="1.5" />
													</button>
												</div>
												<div>
													<button class="btn-transparent text-color-burning-tomato" @click="handleRemoveDocument(file.id)">
														<trash-2 :size="40" :stroke-width="1.5" />
													</button>
												</div>
											</div>
										</div>
										<img :src="`svgs/bootstrap/filetype-${getExtensionFile(file)}.svg`" class="file" />
									</div>
									<div class="file-name mt-3">
										{{ getFileName(file.fileName) }}
									</div>
									<div class="wrap-status mt-1 pb-4">
										<div :class="`status ${classDocumentStatus(file.status)}`" style="font-size: 0.7rem">
											{{ validatedStatus(file.status) }}
										</div>
									</div>
								</b-col>
								<div class="add-doc-package ml-3 mt-3">
									<div class="add-doc-package-action">
										<button class="btn-transparent text-color-white" @click="handleClickNewDocument">
											<plus-circle :size="75" :stroke-width="0.5" />
										</button>
									</div>
								</div>
							</b-row>
						</div>
					</div>
				</b-card>
			</div>
			<add-document :open="showModalDocument" :parent-id="parentId" @add-document-modal:hide="onCancelled" @add-document-modal:success="onSuccess" />
		</div>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import * as icons from 'lucide-vue';
import { PlusCircle, Eye, Trash2 } from 'lucide-vue';
import { getDocument, deleteDocument } from '@/modules/document-package/cruds/document.crud';
import { store } from '@/store';
import moment from 'moment';
import { classDocumentStatus } from '@/shared/global-status';
import AddDocument from '@/views/documents/AddDocumentModal';
import globalMixin from '@/mixins/global.mixin';
import { getFileSrc, getFileExtension } from '@/shared/helpers';
import MyDocumentDetailsMobile from '@/components/MyDocumentsMobile/MyDocumentDetailsMobile';
import { mapActions } from 'vuex';

const EXT_TO_ACCEPT = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'md', 'txt'];

export default {
	name: 'MyDocumentDetails',
	mixins: [languageMessages, globalMixin],
	components: {
		PlusCircle,
		Eye,
		Trash2,
		AddDocument,
		MyDocumentDetailsMobile
	},
	computed: {
		me() {
			return store.state.myProfile.id;
		}
	},
	data() {
		return {
			error: {},
			document: {},
			fileList: [],
			mineFileList: [],
			showModalDocument: false,
			parentId: 0
		};
	},
	async created() {
		await this.reloadData();

		this.$previewerEventListener('mydocumentdetails-previewer-elem', (e) => {
			const {
				on_documents_previewer_event__: { type }
			} = e;
			if (type === 'close') {
				this.$nextTick(async () => {
					await this.getNotifications();
					this.reloadData();
				});
			}
		});
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		async reloadData() {
			this.fileList = [];
			this.mineFileList = [];

			const { id, contractId } = this.$route.params;
			this.parentId = id;

			await getDocument(
				id,
				{
					showOnlyMyDocument: true
				},
				store.userID(),
				contractId
			).then((record) => {
				this.document = record;

				if (this.document.type === 11) {
					// store.state.myProfile.id
					this.fileList = this.document.childDocList.filter((list) => list.userId === null || parseInt(list.userId, 10) === 0);
					this.fileList = this.fileList.map((doc) => {
						const SRC = getFileSrc(doc, { contractId: doc.deliveryContractId });
						return {
							...doc,
							fileName: this.replaceToRightFileNameExt(doc.fileName, doc.docSignedXid),
							type: doc.type,
							xid: this.getRightXidDocument(doc.xid, doc.docSignedXid),
							ext: getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)),
							status: doc.status,
							toSign: doc.toSign,
							src: `${SRC}?deliveryParentId=${doc.firstDeliveryParentId}`,
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + doc.xid,
							isHovered: false
						};
					});

					this.mineFileList = this.document.childDocList.filter((list) => parseInt(list.userId, 10) === parseInt(this.me, 10));
					this.mineFileList = this.mineFileList.map((doc) => {
						const SRC = getFileSrc(doc, { contractId: doc.deliveryContractId });
						return {
							...doc,
							fileName: this.replaceToRightFileNameExt(doc.fileName, doc.docSignedXid),
							type: doc.type,
							xid: this.getRightXidDocument(doc.xid, doc.docSignedXid),
							ext: getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)),
							status: doc.status,
							toSign: doc.toSign,
							src: `${SRC}?deliveryParentId=${doc.firstDeliveryParentId}`,
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(doc.xid, doc.docSignedXid),
							isHovered: false
						};
					});
				} else {
					const SRC = getFileSrc(this.document, { imageOnly: false, contractId: this.document.deliveryContractId });
					this.fileList.push({
						...this.document,
						fileName: this.replaceToRightFileNameExt(this.document.fileName, this.document.docSignedXid),
						type: this.document.type,
						xid: this.getRightXidDocument(this.document.xid, this.document.docSignedXid),
						ext: getFileExtension(this.getRightXidDocument(this.document.xid, this.document.docSignedXid)),
						status: this.document.status,
						toSign: this.document.toSign,
						src: `${SRC}?deliveryParentId=${this.document.firstDeliveryParentId}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(this.document.xid, this.document.docSignedXid),
						isHovered: false
					});
				}
				// console.log({document: this.document})
			});
		},
		getIconLucide(name) {
			return icons[name];
		},
		goBack() {
			const path = '/mydocuments';
			this.$router.push({ path });
		},
		getLiteralDate(date) {
			return moment(date).format('DD/MM/YYYY HH:mm');
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		getExtensionFile(file) {
			let filename = this.replaceToRightFileNameExt(file.fileName, file.docSignedXid);
			return filename.substring(filename.lastIndexOf('.') + 1);
		},
		/**
		 * @param {String or Number} value
		 * @return {String}
		 */
		validatedStatus(value) {
			const statuses = this.FormMenu(1012);
			const status = statuses.filter((status) => status.value === value);

			return status.length > 0 ? status[0].text : '';
		},
		classDocumentStatus(status) {
			return classDocumentStatus(status);
		},
		contractTranslation(value) {
			const contractTypes = this.FormMenu(1011);
			const contractType = contractTypes.filter((contractType) => contractType.value === value);

			return contractType.length > 0 ? contractType[0].text : '';
		},
		handleClickNewDocument() {
			this.showModalDocument = true;
		},
		onCancelled() {
			this.showModalDocument = false;
		},
		handleMouseEnter(document) {
			document.isHovered = true;
		},
		handleMouseLeave(document) {
			document.isHovered = false;
		},
		handlePreviewDocument(file) {
			let fileToPreview = {
				...file
			};

			if (EXT_TO_ACCEPT.includes(file.ext)) {
				fileToPreview = {
					...file,
					status: this.manageStatusInAdvance(file.status)
				};
			}

			this.$previewImages({
				name: 'mydocumentdetails-previewer-elem',
				images: [fileToPreview],
				focusIndex: 0,
				options: {
					presentationMode: 'doc',
					showStatusFileTag: true,
					hideCommentButton: false,
					hideDisLikeButton: false,
					hideLikeDislike: false,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: true
				}
			});
		},
		onSuccess(payload) {
			if (payload) {
				this.createToastForMobile(this.FormMSG(18, 'Success'), this.FormMSG(19, 'Document added successfully'), '');
				this.mineFileList.push(payload); // console.log({payload})
				this.showModalDocument = false;
			}
		},
		handleRemoveDocument(id) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(14, 'Are you sure to remove this document ?'), {
					title: this.FormMSG(15, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(16, 'YES'),
					cancelTitle: this.FormMSG(17, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					await deleteDocument(id)
						.then((result) => {
							if (result) {
								this.mineFileList.splice(
									this.mineFileList.findIndex((file) => file.id === id),
									1
								);
							}
						})
						.catch((e) => console.log(e));
				})
				.catch((e) => console.log(e));
		},
		dateToString(date) {
			return new moment(date).utc().format('DD/MM/YYYY');
		}
	}
};
</script>
